// Here you can add other styles


img {
	max-width: 100%;
}

.text-small {
	font-size: 0.8em;
}

.devider {
	width: 100%;
	height: 1px;
	background-color: $gray-300;
	margin: 20px 0;
}


.no-resize {
	resize: none;
}

.pointer {
	cursor: pointer;
}

video {
	max-width: 100%;
	min-width: 100%;
}

.video-block {

	min-height: 250px;

}	

.p-relative {
	position: relative;
}


.absolute-spinner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba($color: #ffffff, $alpha: 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

p {
	margin-bottom: .5rem;
}

.background-black {

	background: black;

}